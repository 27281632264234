<template>
  <div
    class="table"
    :class="['table--' + tableName, {'table--statistics' : !hideStatisticsBar} ]"
    v-resize="resizeTable"
    v-shortkey="navigateTableShortkeys"
    @shortkey="handleShortKey"
  >
    <v-data-table
      v-if="tableParameters && tableParameters.length"
      ref="table"
      :value="selectedItem"
      :headers="tableParameters"
      :items="items"
      item-key="id"
      :search="noApiManipulation() ? config.search : undefined"
      :items-per-page="-1"
      :sort-by="sorting.sortBy"
      :sort-desc="sorting.sortDesc"
      :custom-sort="noApiManipulation() ? undefined : customSorting"
      hide-default-footer
      single-select
      :fixed-header="!disableFitHeight"
      must-sort
      no-results-text="Brak wyników wyszukiwania / filtrowania"
      :loading="isProcessing"
      loading-text="Pobieram elementy..."
      :custom-filter="customSearch"
      :expanded.sync="expandedItems"
      :mobile-breakpoint="0"
      @update:options="handleChangeTableOptions"
    >
      <template #top>
        <slot name="tableTop" />
      </template>
      <template
        #[`header.actions`]
        v-if="!disablePersonalization"
      >
        <TablePersonalization
          :table-name="tableName"
          :config="config"
        />
      </template>
      <template
        v-for="shortParam in tableParameters.filter(param => param.shortText)"
        #[`header.${shortParam.value}`]
      >
        <v-tooltip
          :key="shortParam.value"
          bottom
        >
          <template #activator="{ on }">
            <span v-on="on">{{ shortParam.shortText }}</span>
          </template>
          <span>{{ shortParam.text }}</span>
        </v-tooltip>
      </template>
      <template
        #[`header.select`]
        v-if="isMultiselectActive"
      >
        <TableHeaderSelect :table-name="tableName" />
      </template>
      <template #expanded-item="{ headers, item: expandedItem }">
        <tr>
          <td
            :colspan="headers.length"
            style="padding:0"
          >
            <v-data-table
              class="table br-0"
              :class="['table--' + tableName, {'table--statistics' : !hideStatisticsBar} ]"
              :headers="expandedTableParameters"
              :items="expandedItem.groupedEntries"
              :items-per-page="-1"
              hide-default-footer
              hide-default-header
            >
              <template #[`header`]>
                <tr>
                  <th
                    v-for="(parameter, headerIndex) in expandedTableParameters"
                    :key="headerIndex"
                    :style="{ width: `${parameter.width}px`, height: '0px' }"
                  />
                </tr>
              </template>
              <template #item="{ item: expandedTableItem, index: expandedTableItemIndex }">
                <slot name="row">
                  <TableRow
                    ref="tableRow"
                    :table-name="tableName"
                    :item="expandedTableItem"
                    :disabled="isProcessing"
                    :is-expanded-table-item="true"
                    :header="tableParameters"
                    :actions-offset="actionsOffset"
                    @contextmenu.native.prevent="handleContextMenu($event, expandedTableItem, expandedTableItemIndex)"
                    @dblclick.native="!isMultiselectActive && [setActiveItemInUrlOnRowClick(expandedTableItem), $emit('openDetails', expandedTableItem)]"
                    v-touch:tap="!isMultiselectActive && [setActiveItemInUrlOnRowClick(expandedTableItem), $emit('openDetails', expandedTableItem)]"
                  />
                </slot>
              </template>
            </v-data-table>
          </td>
        </tr>
      </template>
      <template #item="{ item, index, expand, isExpanded }">
        <slot name="row">
          <TableRow
            ref="tableRow"
            :class="[setRowClass(item), { 'table__row--selected': index === contextMenuElementIdx || isExpanded}]"
            :table-name="tableName"
            :item="item"
            :header="tableParameters"
            :disabled="isProcessing"
            :is-selected="index === selectedRow"
            :is-multiselect-active="isMultiselectActive"
            :actions-offset="actionsOffset"
            :expanded-items="expandedItems"
            :empty-value="item.groupedEntries ? '' : '-'"
            @expandRow="[expand(!isExpanded), selectRow(item, index)]"
            @contextmenu.native.prevent="handleContextMenu($event, item, index)"
            @click.native="selectRow(item, index)"
            @dblclick.native="!isMultiselectActive && [setActiveItemInUrlOnRowClick(item), $emit('openDetails', item)]"
          />
        </slot>
      </template>
      <template #footer>
        <div
          class="table-statistics"
          ref="statisticsBar"
          v-if="!hideStatisticsBar"
        >
          <div class="table-statistics__value mr-2">
            {{ countItems[0] }}
          </div>
          <div class="table-statistics__label">
            {{ countItems[1] }}
          </div>
          <slot name="stats" />
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { noApiManipulation, declensionName, getExecutiveMultiselectTableName } from '../../utils'
import { navigateTableShortkeys } from '../../const/shortKeys'
import { mapActions, mapState } from 'vuex'
import groupedEntriesMixin from '../../mixins/groupedEntriesMixin'
import filter from 'lodash/filter'
import TableRow from './TableRow'
import TableHeaderSelect from './Headers/TableHeaderSelect'
import TablePersonalization from './TablePersonalization'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'

export default {
  components: {
    TableHeaderSelect,
    TableRow,
    TablePersonalization
  },
  mixins: [
    groupedEntriesMixin // expandedItems, expandRow, getGroupedEntries
  ],
  props: {
    tableName: {
      type: String,
      required: true
    },
    customTableHeader: {
      type: Array,
      required: false
    },
    maxHeight: {
      type: Number,
      required: false
    },
    disablePersonalization: {
      type: Boolean,
      default: () => false
    },
    disableFitHeight: {
      type: Boolean,
      default: () => false
    },
    disablePagination: {
      type: Boolean,
      default: false
    },
    topOffset: {
      type: Number,
      default: 0,
    },
    hideStatisticsBar: {
      type: Boolean,
      default: false
    },
    overwrittenItems: {
      type: Array,
      required: false
    },
    customSearch: {
      type: Function,
      required: false
    },
    setRowClass: {
      type: Function,
      default: () => ''
    },
    hasGroupedEntries: {
      type: Boolean,
      required: false,
      default: false
    },
    currentTab: {
      type: [String, Number],
      required: false
    }
  },
  data: () => ({
    firstInit: true,
    height: 1000,
    selectedItem: [],
    selectedRow: -1,
    navigateTableShortkeys,
    statistics: {},
    actionsOffset: {
      tableVisibleArea: 0,
      tableWidth: 0,
      scrolled: 0
    },
    contextMenuElementIdx: -1,
  }),
  computed: {
    ...mapState({
      sidebar: state => state.layout.sidebar,
      department: state => state.core.department,
      isDialogOpen: state => state.layout.dialog.active,
      isMultiselectActive: function (state) {
        if (!this.$isLogisticsApp) return false
        const tableName = getExecutiveMultiselectTableName(this.tableName)
        return state[tableName]?.isMultiselectActive
      },
      isProcessing: function (state) {
        return state[this.tableName].isProcessing
      },
      selectedItems: function (state) {
        if (!this.$isLogisticsApp) return false
        const tableName = getExecutiveMultiselectTableName(this.tableName)
        return state[tableName]?.selectedItems || []
      },
    }),
    tableDateRange () {
      return this.$store.state[this.tableName].dateRange
    },
    activeItemId () {
      return this.$route.query?.activeItemId
    },
    config () {
      return this.$store.getters['tables/getTableConfig'](this.tableName)
    },
    items () {
      // wouldn't it be more convenient to fetch collection and all setup in a parent component (eg views/Courses.vue)
      // and pass it as props to Table component?
      const items = this.overwrittenItems ? this.overwrittenItems : this.$store.state[this.tableName].items
      return this.hasGroupedEntries ? this.getGroupedEntries(items) : items
    },
    countItems () {
      const { totalItemsCount } = this.$store.state[this.tableName] || {}
      return declensionName(totalItemsCount, 'element', 'elementy', 'elementów').split(' ')
    },
    tableParameters () {
      const parameters = filter(this.config.parameters, 'show') // filter out hidden parameters
      return parameters.filter(param => param.tabs ? param.tabs.includes(this.currentTab) : true) // filter out parameters that are not in current tab
    },
    sorting () {
      return {
        sortBy: this.config.sorting.sortBy,
        sortDesc: this.config.sorting.sortDesc
      }
    },
    tableWrapper () {
      return this.$refs.table?.$el?.querySelector(`.table--${this.tableName} .v-data-table__wrapper`)
    },
    isAnyFilterSelected () {
      return this.$store.getters['tables/isAnyFilterSelected'](this.tableName)
    },
    isFiltersBarShown () {
      return this.$store.state[this.tableName]?.showFilters
    },
    clonedConfigFilters () {
      return cloneDeep(this.config.filters)
    }, // due to problem with reactivity we need to clone object
    expandedTableParameters() {
      return this.tableParameters.map((param) => {
        return { ...param, width: Math.floor(param.width * 1.135 * 100) / 100 }
      })
    }, // strange but works - we need to increase width of expanded table headers by 13,5% to align with main table
  },
  watch: {
    activeItemId (id) {
      this.setActiveItem(id)
    },
    'config.search' () { this.resetScrollPosition() },
    'config.filtersEnabled' () { this.resetScrollPosition() },
    clonedConfigFilters: { // now get diffrent newValue and oldValue, previously we always got new value in both
      deep: true,
      handler (newValue, oldValue) { // watcher is still triggeted if nothing changes, but now we can compare values
        if (!isEqual(oldValue, newValue)) this.resetScrollPosition()
      }
    },
    'config.sorting': {
      deep: true,
      handler () { this.resetScrollPosition(true, false) }
    },
    tableDateRange () { this.resetScrollPosition() },
    items (newValue) {
      const selectedItemId = this.selectedItem[0]?.id
      if (this.activeItemId && newValue?.every(item => !item?.fromWS) && selectedItemId !== Number(this.activeItemId)) this.setActiveItem(this.activeItemId)
      this.filterSelectedItems()
      this.$nextTick(() => { this.resizeTable() })
    },
    isAnyFilterSelected () {
      if (!this.firstInit) this.$nextTick(() => { this.resizeTable() })
    },
    isFiltersBarShown () {
      if (!this.firstInit) this.$nextTick(() => { this.resizeTable() })
    },
    topOffset () {
      if (!this.firstInit) this.$nextTick(() => { this.resizeTable() })
    },
  },
  mounted () {
    const filtersDrivenTables = ['courses', 'coursesWithoutCards', 'clients', 'clientOrders', 'clientInvoices', 'clientAppOrders', 'containerTypes', 'invoices', 'orders', 'orderTemplates', 'tasks', 'debrisPrices', 'drivers']
    const disableFetch = filtersDrivenTables.includes(this.tableName)
    this.setTableConfig({ tableName: this.tableName, disableFetch })
      .then(() => {
        this.tableWrapper.addEventListener('scroll', this.moveActions)
        if (!this.disablePagination) {
          this.tableWrapper.addEventListener('scroll', this.loadNextPage)
        }
        this.$emit('configured', this.tableName)
        this.resizeTable()
      })
  },
  beforeDestroy () {
    this.unselectAll()
    this.toggleMultiselectStatus(false)
    this.tableWrapper.removeEventListener('scroll', this.moveActions)
    if (!this.disablePagination) {
      this.tableWrapper.removeEventListener('scroll', this.loadNextPage)
    }
  },
  methods: {
    ...mapActions({
      setTableConfig: 'tables/setTableConfig',
      setTableSorting: 'tables/setTableSorting',
      toggleMultiselectStatus: function (dispatch, show) {
        if (!this.$isLogisticsApp) return
        const tableName = getExecutiveMultiselectTableName(this.tableName)
        return dispatch(`${tableName}/toggleMultiselectStatus`, show)
      },
      goNextItemsPage (dispatch, payload) {
        return dispatch(`${this.tableName}/goNextItemsPage`, payload)
      },
      filterSelectedItems (dispatch) {
        return dispatch(`${this.tableName}/filterSelectedItems`)
      }
    }),
    moveActions () {
      const { scrollLeft } = this.tableWrapper
      if (scrollLeft !== this.actionsOffset.scrolled) {
        const { tableVisibleArea, tableWidth } = this.actionsOffset
        this.actionsOffset.scrolled = (scrollLeft + tableVisibleArea < tableWidth)
          ? scrollLeft : tableWidth - tableVisibleArea
      }
    },
    noApiManipulation () {
      return noApiManipulation(this.tableName)
    },
    handleChangeTableOptions (options) {
      if (this.firstInit) {
        this.firstInit = false
      } else {
        this.unselectAll()
        const sorting = {
          sortBy: options.sortBy[0],
          sortDesc: options.sortDesc[0]
        }
        this.setTableSorting({ sorting, tableName: this.tableName })
      }
    },
    setActiveItemInUrlOnRowClick (item) {
      const id = item.id || item.orderId
      this.$router.replace({ query: { activeItemId: id } })
    },
    setActiveItem (id) {
      this.$nextTick(() => {
        let activeItemIndex = null

        if (this.tableName === 'courses') {
          // in courses table activeItemId can be:
          // - id when we switch between delivery and pickup course
          // - orderId when we go back from single order view to courses table
          activeItemIndex = this.items.findIndex(item => item.id === Number(id) || item.orderId === Number(id))
        } else {
          activeItemIndex = this.items.findIndex(item => item.id === Number(id))
        }

        const activeItem = this.items[activeItemIndex]
        if (activeItem) {
          this.selectRow(activeItem, activeItemIndex)
          const element = this.$refs.table.$el.querySelector(`tbody .table__row:nth-of-type(${activeItemIndex + 1})`)
          element.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      })
    },
    resizeTable () {
      const table = this.$refs.table?.$el
      this.actionsOffset.tableVisibleArea = table?.offsetWidth
      this.actionsOffset.tableWidth = table?.querySelector('table')?.offsetWidth
      if (this.maxHeight || this.disableFitHeight) {
        this.height = this.maxHeight || undefined
      } else {
        this.$nextTick(() => {
          const { header, tabs, tableTop, confirmation } = this.$parent.$refs
          let tableTopHeight = 0
          // TABS HEIGHT
          if (tabs) tableTopHeight += tabs?.$el.clientHeight
          // FILTERS BAR HEIGHT
          if (tableTop) {
            const { clientHeight: filtersHeight } = tableTop.$el || tableTop
            tableTopHeight += filtersHeight
          }
          if (header) tableTopHeight += header?.$el.clientHeight

          this.height = window.innerHeight - tableTopHeight - this.topOffset
          // STATISTICS BAR HEIGHT
          if (!this.hideStatisticsBar) {
            const statsHeight = this.$refs.statisticsBar?.clientHeight || 0
            this.height -= statsHeight
          }
          // CONFIRMATION BAR HEIGHT
          const isConfirmationBarOpen = ['courses', 'coursesWithoutCards'].includes(this.tableName)
            ? this.isMultiselectActive : this.selectedItems.length
          if (isConfirmationBarOpen) this.height -= confirmation?.$el?.clientHeight
          const tableMaxHeight = this.height < 320 ? 320 : this.height
          this.tableWrapper.style.maxHeight = `${tableMaxHeight}px`
        })
      }
    },
    loadNextPage () {
      const { tableWrapper } = this
      const offset = 20 // insurance for browser scaling
      if (tableWrapper.scrollTop + tableWrapper.clientHeight >= tableWrapper.scrollHeight - offset) {
        const { page, pageCount } = this.config.pagination
        if (page < pageCount) {
          this.goNextItemsPage(this.tableName)
        }
      }
    },
    handleShortKey (event) {
      if (!this.isDialogOpen) {
        switch (event.srcKey) {
          case 'up':
            if (this.sidebar.size < 2) this.selectRowByIndex(this.selectedRow - 1)
            break
          case 'down':
            if (this.sidebar.size < 2) this.selectRowByIndex(this.selectedRow + 1)
            break
          case 'enter':
            if (this.selectedItem.length) {
              this.setActiveItemInUrlOnRowClick(this.selectedItem[0])
              this.$emit('openDetails', this.selectedItem[0])
            }
            break
          case 'left':
          case 'esc':
            this.$emit('closeDetails')
            break
        }
      }
    },
    selectRowByIndex (index) {
      if ((index >= 0) && (index < this.items.length)) {
        this.tableScrollTop(index)
        const item = this.items[index]
        this.selectedRow = index
        this.selectedItem = [item]
        if (this.sidebar.size) this.$emit('selectRow', item)
      }
    },
    selectRow (item, index) {
      this.contextMenuElementIdx = -1
      if (!this.selectedItem.length || this.selectedItem[0].id !== item.id) {
        this.selectedRow = index
        this.selectedItem = [item]
      } else {
        this.selectedRow = -1
        this.selectedItem = []
      }
      this.$emit('selectRow', item)
    },
    handleContextMenu (e, item, index) {
      this.selectedRow = -1
      this.contextMenuElementIdx = index
      window.addEventListener('click', this.onClickOutside)
      this.$emit('contextMenu', {
        item,
        position: { x: e.clientX, y: e.clientY }
      })
    },
    onClickOutside() {
      this.contextMenuElementIdx = -1
      window.removeEventListener('click', this.onClickOutside)
    },
    unselectAll () {
      this.selectedRow = -1
      this.selectedItem = []
    },
    customSorting: function (items) {
      return items
    },
    resetScrollPosition (top = true, left = true) {
      if (!this.firstInit) {
        if (top) this.tableWrapper.scrollTop = 0
        if (left) this.tableWrapper.scrollLeft = 0
      }
    },
    tableScrollTop (index) {
      const tableHeaderHeight = 56
      const rowSelected = this.tableWrapper.querySelector(
        `.table__row:nth-child(${index + 1})`
      ).getBoundingClientRect()

      const { top: wrapperTop, bottom: wrapperBottom } = this.tableWrapper.getBoundingClientRect()
      const { top: rowTop, bottom: rowBottom } = rowSelected

      if (rowBottom > wrapperBottom) {
        this.tableWrapper.scrollTop += rowBottom - wrapperBottom
      } else if (rowTop - tableHeaderHeight < wrapperTop) {
        const offset = tableHeaderHeight - (rowTop - wrapperTop)
        this.tableWrapper.scrollTop -= offset
      }
    },
  },

}

</script>
