import { DateTime, Interval } from 'luxon'
import get from 'lodash/get'
import views from '../const/views'

function dateStringFormat (dateString, time) {
  const format = time ? 'd/MM/yyyy | HH:mm:ss' : 'd/MM/yyyy'
  return dateString === '-' ? '-' : DateTime.fromISO(dateString).toFormat(format)
}

const noApiManipulation = (tableName) => views[tableName]?.table.noApi

const ignoreDateRange = (tableName) => views[tableName]?.table.noDateRange

const getTableOptions = (tableName) => views[tableName]?.table

const mergeTableParameters = (oldParams, newParams) => {
  const actions = oldParams[oldParams.length - 1]
  oldParams = oldParams.filter((param) =>
    (param.name !== 'actions' && param.name !== 'price') || (param.name === 'price' && param.multiple))
  oldParams.push([...newParams, actions])
  return oldParams.flat()
}

const countCourses = (stats, courses) => {
  for (const course of courses) {
    if (course.swapCourse) stats.Wymiana.value++
    else stats[course.courseType].value++
  }
  return stats
}

const getCoursesStatistics = (items, callback = countCourses) => {
  const statistics = {
    Podstawienie: { text: 'Podstawienia:', value: 0 },
    Zabranie: { text: 'Zabrania:', value: 0 },
    Wymiana: { text: 'Wymiany:', value: 0 },
    Transport: { text: 'Transport:', value: 0 },
  }
  return callback(statistics, items)
}

const getMultipleTableParameters = (params, items) => {
  const parameters = []
  if (items.length) {
    params.map((param) => {
      items[0][param.multiple].map((el, index) => {
        parameters.push(
          {
            name: param.name,
            text: el[param.text],
            value: `${param.multiple}[${index}].${param.value}`,
            sortable: param.sortable,
            show: true
          }
        )
      })
    })
  }
  return parameters
}

const getTableDetails = (tableName) => views[tableName].details || {}

const getTableDaterangeCriteria = (tableName) => views[tableName].table.daterangeCriteria || {}

const getTableWsData = (tableName) => views[tableName]?.webSockets || {}

const setTableDetailsView = (tableName, emptyOrder, data) => {
  const details = {
    left: [],
    right: [],
    bottom: []
  }
  const sections = emptyOrder ? 'emptyOrderSections' : 'sections'
  getTableDetails(tableName)[sections]
    .filter(section => !section.condition || section.condition(data))
    .map((section) => {
      section.multiple = section.multiple ? getGroupedItemValue(section.multiple) : null
      if (section.fields) {
        section.fields.map(field => ({ ...field, value: getGroupedItemValue(field.value) }))
      }
      details[section.type].push(section)
    })
  return details
}

const getTableMaximumDateRange = (tableName) => views[tableName].table?.maximumDateRange || 92

const getTitleFormatted = (title, data) => {
  const regex = /[^{{]+(?=}\})/g
  const matches = title.match(regex)
  const values = matches?.map((item) => get(data, item, ''))
  matches?.map((item, index) => {
    title = title.replace('{{' + item.toString() + '}}', values[index] || '')
  })
  return title.replace(/;[^;]$/, '')
}

const getGroupedItemValue = (path, index = 0) => typeof path === 'string' ? path : path[index]

const getLocalItem = (name) => JSON.parse(localStorage.getItem(name))

const setLocalItem = (name, item) => localStorage.setItem(name, JSON.stringify(item))

const readJSON = (source) => JSON.parse(JSON.stringify(source))

const filterNullFields = (object) => {
  for (const prop in object) {
    if (object[prop] === null) delete object[prop]

    if (typeof object[prop] === 'object' && !Array.isArray(object[prop])) {
      object[prop] = filterNullFields(object[prop])
    }
  }
  return object
}

const parseFloatNumber = (number) => {
  if (typeof number === 'string' && number.includes(',')) {
    number = number.split(',').join('.')
  }
  return isNaN(+number) ? 0 : +number
}

const parseAsBasicUnit = (value, factor = 100) => {
  if (value) return Math.round(parseFloatNumber(value) * factor)
  return undefined
}

const declensionName = (length = 0, singularName, pluralNominativeName, pluralGenitiveName) => {
  let label = singularName

  if (length % 10 < 5 && length % 10 > 1 && (length % 100 > 14 || length % 100 < 12)) {
    label = pluralNominativeName
  } else if (length !== 1) {
    label = pluralGenitiveName
  }
  return `${length} ${label}`
}
function downloadFile (url, filename, target) {
  if (process.env.IS_ELECTRON) {
    window.ipcRenderer.send('download-item', {
      url,
      properties: { saveAs: true }
    })
  } else {
    const link = document.createElement('a')
    link.target = target
    link.href = url
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

function openNewWindow ({ path, query, params, name }) {
  const { route: { fullPath } } = this.$router.resolve({ path, query: { full: true, ...query }, params, name })
  if (process.env.IS_ELECTRON) {
    window.open('', fullPath)
  } else {
    window.open(fullPath)
  }
}

const getBlockerValue = (item, tableName) => {
  const paths = {
    courses: 'orderClientBlocked',
    clients: 'blocked',
    orders: 'clientBlocked',
    orderSchedules: 'active',
    order: 'client.blocked'
  }
  if (tableName === 'orderSchedules') {
    return !get(item, paths[tableName])
  }
  return get(item, paths[tableName]) || false
}

const getFieldMask = (fieldName) => {
  const masks = {
    phoneNumber: '###-###-###',
    postCode: '##-###',
    bankAccount: '## #### #### #### #### #### ####'
  }
  return masks[fieldName]
}

const formatChangedPhoneNumber = (phoneNumber) => {
  var match = phoneNumber.match(/^(\d{3})(\d{3})(\d{3})$/)
  if (match && match.length === 4) {
    return match[1] + '-' + match[2] + '-' + match[3]
  }
  return phoneNumber
}

const getDistanceBetweenTwoPoints = ({ baseLat, baseLng }, { destLat, destLng }) => {
  var R = 6371 // Radius of the earth in km
  var dLat = parseDegToRad(destLat - baseLat) // parseDegToRad below
  var dLon = parseDegToRad(destLng - baseLng)
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(parseDegToRad(baseLat)) * Math.cos(parseDegToRad(destLat)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)

  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c // Distance in km
  return +d.toFixed(1)
}

const parseDegToRad = (deg) => {
  return deg * (Math.PI / 180)
}

const isFilterActive = (filterBy) => {
  if (Array.isArray(filterBy)) return filterBy.length
  return filterBy || typeof filterBy === 'boolean'
  // inactive filter is either [] or ''
}

const searchDebrisType = (item, queryText, itemText) => {
  const searchText = (itemText + item.codeParsed).toLowerCase()
  return searchText.indexOf(queryText.toLowerCase()) > -1
}

const getDebrisString = (debris) => {
  return debris.code ? `${debris.code} - ${debris.displayName}` : debris.displayName
}

const roundToDecimalPoint = (value, decimalPoint) => {
  return Math.round(value * Math.pow(10, decimalPoint)) / Math.pow(10, decimalPoint)
}

function getNetValue (grossValue, taxValue = 8) {
  const value = parseFloatNumber(grossValue)
  if (value > 10000001) return 0
  let amount = value / (1 + taxValue / 100)
  amount = roundToDecimalPoint(amount, 5)
  return amount || 0
}

function getGrossValue (netValue, taxValue = 8) {
  const value = parseFloatNumber(netValue)
  if (value > 10000001) return 0
  const taxAmount = value ? (value * taxValue / 100) : 0
  const amount = value + roundToDecimalPoint(taxAmount, 5)
  return amount || 0
}

function getExecutiveMultiselectTableName (originTable) {
  if (originTable === 'clientOrders') return 'orders'
  if (originTable === 'clientInvoices') return 'invoices'
  return originTable
}

const checkDateRange = (date, daterange) => {
  const [start, end] = daterange
  const range = new Interval({
    start: DateTime.fromISO(start).startOf('day'),
    end: DateTime.fromISO(end || start).endOf('day')
  })
  return range.contains(DateTime.fromISO(date))
}

const parseTableParameters = (table, savedTable) => {
  // table - full table config
  // savedTable - saved table config in BE
  const { parameters: tableParameters } = table
  const { hiddenParameters, parameters: savedTableParameters } = savedTable

  // Create a map to store the index of each element in the savedTableParameters
  const indexMap = new Map()
  savedTableParameters.forEach((element, index) => {
    indexMap.set(element.name, index)
  })

  // Sort the tableParameters based on the index in the savedTableParameters
  tableParameters.sort((a, b) => indexMap.get(a.name) - indexMap.get(b.name))

  // Sort and modify the tableParameters based on the hiddenParameters (show/hide)
  return [...tableParameters].sort((x, y) => {
    const existsX = hiddenParameters.includes(x.name)
    const existsY = hiddenParameters.includes(y.name)

    if (existsX && !existsY) {
      return 1 // x comes first
    } else if (!existsX && existsY) {
      return -1 // y comes first
    } else if (existsX && existsY) {
      return hiddenParameters.indexOf(x.name) - hiddenParameters.indexOf(y.name) // sort by index in b
    } else {
      return 0 // leave unchanged
    }
  }).map((config) => {
    return { ...config, show: !hiddenParameters?.includes(config.name) }
  })
}

const mapTableFilters = (fullConfig, savedConfig) => {
  return fullConfig.map((filter) => {
    const savedFilter = savedConfig.find(savedFilter => savedFilter.name === filter.name)
    return savedConfig.map(filter => filter.name).includes(filter.name) ? {
      ...filter,
      filterBy: savedFilter?.filterBy || filter.filterBy
    } : filter
  })
}

const getGmapsAddressParts = (address) => {
  const streetRegex = /<span class="street-address">(.*?)<\/span>/
  const postalCodeRegex = /<span class="postal-code">(.*?)<\/span>/
  const cityRegex = /<span class="locality">(.*?)<\/span>/

  const streetMatch = address.adr_address.match(streetRegex)
  const postalCodeMatch = address.adr_address.match(postalCodeRegex)
  const cityMatch = address.adr_address.match(cityRegex)

  const street = streetMatch ? streetMatch[1] : ''
  const postalCode = postalCodeMatch ? postalCodeMatch[1] : ''
  const city = cityMatch ? cityMatch[1] : ''
  const formattedAddress = `${city}, ${street} ${postalCode}`
  const lat = address.geometry.location.lat().toString()
  const lng = address.geometry.location.lng().toString()

  return { formattedAddress, lat, lng }
}

const getPaymentOrigin = (payment) => {
  const paymentOrigin = {
    P: 'Produkt',
    C: 'Cennik',
    R: 'Ręcznie',
    Z: 'Ostatnie wykonane zlecenie'
  }
  return paymentOrigin[payment.toUpperCase()] || payment
}

export {
  getTableDetails,
  setTableDetailsView,
  getTableMaximumDateRange,
  getTitleFormatted,
  getTableOptions,
  getTableWsData,
  mergeTableParameters,
  getMultipleTableParameters,
  getGroupedItemValue,
  dateStringFormat,
  noApiManipulation,
  ignoreDateRange,
  getLocalItem,
  setLocalItem,
  readJSON,
  filterNullFields,
  parseFloatNumber,
  parseAsBasicUnit,
  declensionName,
  downloadFile,
  openNewWindow,
  getBlockerValue,
  getFieldMask,
  getDistanceBetweenTwoPoints,
  isFilterActive,
  getCoursesStatistics,
  searchDebrisType,
  getDebrisString,
  formatChangedPhoneNumber,
  getNetValue,
  getGrossValue,
  getExecutiveMultiselectTableName,
  checkDateRange,
  getTableDaterangeCriteria,
  parseTableParameters,
  mapTableFilters,
  getGmapsAddressParts,
  getPaymentOrigin,
  roundToDecimalPoint,
}
