import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import Resize from 'vuetify/lib/directives/resize';
import Touch from 'vuetify/lib/directives/touch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.resizeTable),expression:"resizeTable"},{name:"shortkey",rawName:"v-shortkey",value:(_vm.navigateTableShortkeys),expression:"navigateTableShortkeys"}],staticClass:"table",class:['table--' + _vm.tableName, {'table--statistics' : !_vm.hideStatisticsBar} ],on:{"shortkey":_vm.handleShortKey}},[(_vm.tableParameters && _vm.tableParameters.length)?_c(VDataTable,{ref:"table",attrs:{"value":_vm.selectedItem,"headers":_vm.tableParameters,"items":_vm.items,"item-key":"id","search":_vm.noApiManipulation() ? _vm.config.search : undefined,"items-per-page":-1,"sort-by":_vm.sorting.sortBy,"sort-desc":_vm.sorting.sortDesc,"custom-sort":_vm.noApiManipulation() ? undefined : _vm.customSorting,"hide-default-footer":"","single-select":"","fixed-header":!_vm.disableFitHeight,"must-sort":"","no-results-text":"Brak wyników wyszukiwania / filtrowania","loading":_vm.isProcessing,"loading-text":"Pobieram elementy...","custom-filter":_vm.customSearch,"expanded":_vm.expandedItems,"mobile-breakpoint":0},on:{"update:expanded":function($event){_vm.expandedItems=$event},"update:options":_vm.handleChangeTableOptions},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._t("tableTop")]},proxy:true},(!_vm.disablePersonalization)?{key:"header.actions",fn:function(){return [_c('TablePersonalization',{attrs:{"table-name":_vm.tableName,"config":_vm.config}})]},proxy:true}:null,_vm._l((_vm.tableParameters.filter(function (param) { return param.shortText; })),function(shortParam){return {key:("header." + (shortParam.value)),fn:function(){return [_c(VTooltip,{key:shortParam.value,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(shortParam.shortText))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(shortParam.text))])])]},proxy:true}}),(_vm.isMultiselectActive)?{key:"header.select",fn:function(){return [_c('TableHeaderSelect',{attrs:{"table-name":_vm.tableName}})]},proxy:true}:null,{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var expandedItem = ref.item;
return [_c('tr',[_c('td',{staticStyle:{"padding":"0"},attrs:{"colspan":headers.length}},[_c(VDataTable,{staticClass:"table br-0",class:['table--' + _vm.tableName, {'table--statistics' : !_vm.hideStatisticsBar} ],attrs:{"headers":_vm.expandedTableParameters,"items":expandedItem.groupedEntries,"items-per-page":-1,"hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',_vm._l((_vm.expandedTableParameters),function(parameter,headerIndex){return _c('th',{key:headerIndex,style:({ width: ((parameter.width) + "px"), height: '0px' })})}),0)]},proxy:true},{key:"item",fn:function(ref){
var expandedTableItem = ref.item;
var expandedTableItemIndex = ref.index;
return [_vm._t("row",function(){return [_c('TableRow',{directives:[{def: Touch,name:"touch",rawName:"v-touch:tap",value:(!_vm.isMultiselectActive && [_vm.setActiveItemInUrlOnRowClick(expandedTableItem), _vm.$emit('openDetails', expandedTableItem)]),expression:"!isMultiselectActive && [setActiveItemInUrlOnRowClick(expandedTableItem), $emit('openDetails', expandedTableItem)]",arg:"tap"}],ref:"tableRow",attrs:{"table-name":_vm.tableName,"item":expandedTableItem,"disabled":_vm.isProcessing,"is-expanded-table-item":true,"header":_vm.tableParameters,"actions-offset":_vm.actionsOffset},nativeOn:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenu($event, expandedTableItem, expandedTableItemIndex)},"dblclick":function($event){!_vm.isMultiselectActive && [_vm.setActiveItemInUrlOnRowClick(expandedTableItem), _vm.$emit('openDetails', expandedTableItem)]}}})]})]}}],null,true)})],1)])]}},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_vm._t("row",function(){return [_c('TableRow',{ref:"tableRow",class:[_vm.setRowClass(item), { 'table__row--selected': index === _vm.contextMenuElementIdx || isExpanded}],attrs:{"table-name":_vm.tableName,"item":item,"header":_vm.tableParameters,"disabled":_vm.isProcessing,"is-selected":index === _vm.selectedRow,"is-multiselect-active":_vm.isMultiselectActive,"actions-offset":_vm.actionsOffset,"expanded-items":_vm.expandedItems,"empty-value":item.groupedEntries ? '' : '-'},on:{"expandRow":function($event){[expand(!isExpanded), _vm.selectRow(item, index)]}},nativeOn:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenu($event, item, index)},"click":function($event){return _vm.selectRow(item, index)},"dblclick":function($event){!_vm.isMultiselectActive && [_vm.setActiveItemInUrlOnRowClick(item), _vm.$emit('openDetails', item)]}}})]})]}},{key:"footer",fn:function(){return [(!_vm.hideStatisticsBar)?_c('div',{ref:"statisticsBar",staticClass:"table-statistics"},[_c('div',{staticClass:"table-statistics__value mr-2"},[_vm._v(" "+_vm._s(_vm.countItems[0])+" ")]),_c('div',{staticClass:"table-statistics__label"},[_vm._v(" "+_vm._s(_vm.countItems[1])+" ")]),_vm._t("stats")],2):_vm._e()]},proxy:true}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }