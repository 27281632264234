import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{key:_vm.userId},[_c('svg-sprite'),(!_vm.mobileNavigationVisible)?_c(VBtn,{staticClass:"mobile-menu-toggler",attrs:{"text":""},on:{"click":function () { return _vm.mobileNavigationVisible = true; }}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" mdi-menu-close ")])],1):_vm._e(),_c(VNavigationDrawer,_vm._b({staticClass:"main-menu",attrs:{"app":"","dark":"","color":"#384457","width":"202","mini-variant":_vm.sidebarSmall,"mini-variant-width":"80"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('SessionDetails',{attrs:{"mini":_vm.sidebarSmall},on:{"toggle":function($event){_vm.sidebarSmall = !_vm.sidebarSmall}}})]},proxy:true},{key:"append",fn:function(){return [(_vm.isLoadedApp)?_c('LogoutSection',{attrs:{"mini":_vm.sidebarSmall}}):_vm._e()]},proxy:true}]),model:{value:(_vm.navigationDrawerVisible),callback:function ($$v) {_vm.navigationDrawerVisible=$$v},expression:"navigationDrawerVisible"}},'v-navigation-drawer',{
      temporary: _vm.isMobile,
      permanent: !_vm.isMobile
    },false),[_c('Menu')],1),(!_vm.isLoggedIn || _vm.isLoadedApp)?_c(VMain,{key:_vm.userId},[_c('router-view',{key:_vm.department && _vm.department.name})],1):_vm._e(),_c('ContextMenu'),(_vm.$isLogisticsApp && _vm.isDialogActive)?_c('Dialog'):_vm._e(),_c('TheSnackbar'),(_vm.isLoadedApp && _vm.$isLogisticsApp)?[_c('ClientSearchDialog'),_c('DriversOccupancy'),_c('OrderTemplateSnackbar'),_c('TasksSnackbar'),_c('TaskConfirmationSnackbar')]:_vm._e(),_c(VOverlay,{attrs:{"value":_vm.isLoading,"color":"#f7f9fc"}}),_c(VProgressLinear,{attrs:{"active":_vm.isLoading,"indeterminate":_vm.isLoading,"absolute":"","bottom":"","height":"6"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }